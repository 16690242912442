   
  import { get,post,put,del  } from "@/api/requestApi";
import request from '../utils/request'

export default {
	 
  install(Vue) {   
    //  
    Vue.prototype.$request = request 
    Vue.prototype.$get = get 
    Vue.prototype.$post = post 
    Vue.prototype.$put = put 
    Vue.prototype.$del = del 
  }
}
