import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		messageNumber: null,
		cartQty: null
	},
	getters,
	mutations: {
		setMessageNumber(state,data) {
			state.messageNumber = data.messageNumber
		},  
		setCartQty(state,data) {
			state.cartQty = data.cartQty
		},  
		
		addCartQty(state) {
			state.cartQty ++ 
		},  
	},
	actions: {},
	modules: {}
})