import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';

import plugins from './plugins' // plugins
import api from './api' // plugins
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/global.css'
import Pagination from "@/components/Pagination";



import { parseTime } from "@/utils/ruoyi";

const messages = ['success', 'warning', 'info', 'error'];
messages.forEach(type => {
    ElementUI.Message[type] = options => {
        if(typeof options === 'string') {
            options = {
                message: options
            };
            // 默认设置
            options.duration = 1000; // 默认值3000毫秒
            options.showClose = true; // 默认值false
            options.offset = 200; // 默认值20
        }
        options.type = type;
        return ElementUI.Message(options);
    }
}); 

Vue.use(ElementUI)
Vue.use(plugins)
Vue.use(api)

Vue.component('Pagination', Pagination)

Vue.config.productionTip = false
Vue.prototype.parseTime = parseTime

new Vue({
	router,
	store,
	render: h => h(App),

}).$mount('#app')