import request from '@/utils/request'
let gateway = '/web/'  
// export default {
// 	get(url, query) {
// 		return request({
// 			url: gateway + url,
// 			method: 'get',
// 			params: query
// 		})
// 	},
// 	post(url, data) {
// 		return request({
// 			url: gateway + url,
// 			method: 'post',
// 			data: data
// 		})
// 	},
// 	put(url, data) {
// 		return request({
// 			url: gateway + url,
// 			method: 'put',
// 			data: data
// 		})
// 	},
// 	del(url) {
// 		return request({
// 			url: gateway + url,
// 			method: 'delete'
// 		})
// 	}
// }

// // 查询 
export function get(url, query) {
	return request({
		url: gateway + url,
		method: 'get',
		params: query
	})
}
// 新增
export function post(url, data) {
	return request({
		url: gateway + url,
		method: 'post',
		data: data
	})
}

// 修改
export function put(url, data) {
	return request({
		url: gateway + url,
		method: 'put',
		data: data
	})
}

// 删除
export function del(url,data) {
	return request({
		url: gateway + url,
		method: 'delete',
		params: data
	})
}