import Vue from 'vue'
import VueRouter from 'vue-router' 

Vue.use(VueRouter)

const routes = [{
		
		path: '/',
		name: 'index',
		component: () => import("../views/index.vue"),
		meta:{
			title:'万唯'
		},
		redirect : "/index",
		children: [{
				name: 'match',
				path: '/match',
				component: () => import('../views/match/home.vue')
			},
			{
				name: 'gaikuang',
				path: '/gaikuang',
				component: () => import('../views/match/gaikuang.vue')
			},
			{
				name: 'newslist',
				path: '/newslist',
				component: () => import('../views/match/news-list.vue')
			},
			{
				name: 'newsDetail',
				path: '/newsDetail',
				component: () => import('../views/match/news-detail.vue')
			},
			{
				name: 'kecheng',
				path: '/kecheng',
				component: () => import('../views/match/kecheng.vue')
			},
			{
				name: 'kechengD',
				path: '/kechengD',
				component: () => import('../views/match/kecheng-detail.vue')
			},
			{
				name: 'baoming',
				path: '/baoming',
				component: () => import('../views/match/baoming.vue')
			},
			{
				name: 'baomingIndex',
				path: '/baomingIndex',
				component: () => import('../views/match/baoming-index.vue')
			},
			{
				name: 'baomingZl',
				path: '/baomingZl',
				component: () => import('../views/match/baoming-zl.vue')
			},
			{
				name: 'baomingU',
				path: '/baomingU',
				component: () => import('../views/match/baoming-upload.vue')
			},
			{
				name: 'baomingR',
				path: '/baomingR',
				component: () => import('../views/match/baoming-result.vue')
			},
			{
				name: 'pinwei',
				path: '/pinwei',
				component: () => import('../views/match/pinwei.vue')
			},
			{
				name: 'review',
				path: '/review',
				component: () => import('../views/match/review.vue')
			},
			{
				name: 'reviewD',
				path: '/reviewD',
				component: () => import('../views/match/review-detail.vue')
			},
			{
				name: 'bottomC',
				path: '/bottomC',
				component: () => import('../views/bottom-content.vue')
			},
			{
				name: 'bannerD',
				path: '/bannerD',
				component: () => import('../views/match/banner-detail.vue')
			},
			{
				name: 'index',
				path: '/index',
				component: () => import('../views/courseware/home.vue')
			},
			{
				name: 'indexBannerD',
				path: '/indexBannerD',
				component: () => import('../views/courseware/banner-detail.vue')
			},
			{
				name: 'packages',
				path: '/packages',
				component: () => import('../views/courseware/packages.vue')
			},
			{
				name: 'packagesD',
				path: '/packagesD',
				component: () => import('../views/courseware/packages-detail.vue')
			},
			{
				name: 'coursewareD',
				path: '/coursewareD',
				component: () => import('../views/courseware/courseware-detail.vue')
			},
			{
				name: 'create',
				path: '/create',
				component: () => import('../views/courseware/create.vue')
			},
			{
				name: 'createUpload',
				path: '/createUpload',
				component: () => import('../views/courseware/create-upload.vue')
			},
			{
				name: 'userInfo',
				path: '/userInfo',
				component: () => import('../views/user/index.vue')
			},
			{
				name: 'down',
				path: '/down',
				component: () => import('../views/user/down-cart.vue')
			},
			
			{
				name: 'jifen',
				path: '/jifen',
				component: () => import('../views/user/jifen-list.vue')
			},
			{
				path: '/c/:key',
				name: '/c/:key',
				component: () => import("../views/courseware/courseware-detail.vue"),
				meta:{
					isHide:true,
					isShortLink:true
				}
			}, 
			{
				path: '/p/:key',
				name: '/p/:key',
				component: () => import("../views/courseware/packages-detail.vue"),
				meta:{
					isHide:true,
					isShortLink:true
				}
			}, 
		]
	},
	{
		path: '/baomingMb',
		name: 'baomingMb',
		component: () => import("../views/match/baoming-mb.vue")
	},
	
	{
		path: '/login',
		name: 'login',
		component: () => import("../views/login.vue")
	},
	{
		path: '*',
		component: () => import("../views/404.vue")
	},
]

const router = new VueRouter({
	mode: 'hash', // 去掉url中的#
	routes,
	scrollBehavior() {
		// return返回期望滚动到的位置的坐标
		return { x: 0, y: 0 }
	}
})

export default router